<template>
  <div>
    <v-form ref="product_form">
      <v-row class="mb-4">
        <v-col>
          <h2 v-if="isAddProduct">Add Product</h2>
          <h2 v-else>Edit Product</h2>
        </v-col>
      </v-row>
      <v-card elevation="0" class="px-6 py-4 mb-8">
        <v-row>
          <v-col>Latest update: 2021-01-12</v-col>
          <v-col>
            <div class="d-flex justify-end">
              <div>Active Product</div>
              <div>
                <v-switch
                color="#0d6efd"
                value
                input-value="true"
                height="0"
                v-model="product_data.active"
                ></v-switch>
            </div>
          </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3>Product Images</h3>
          </v-col>
        </v-row>
        <v-row>
            <v-col xl="12" lg="12" md="12" sm="12" col="12">                
                <v-img 
                  width="150"
                  height="150"
                  :src="product_image"
                >
                </v-img>
                <v-file-input
                    id="product_image"
                    v-show="false"
                    accept="image/*"
                    @change="getEventImageFile($event)"
                ></v-file-input>
                <br>
                <v-btn color="primary" @click="triggerUploadImage('product_image')" :loading="submitLoading"
                >UPLOAD</v-btn>
            </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              type="text"
              label="Product Name"
              outlined
              hide-details="auto"
              v-model="product_data.name"
              :rules="[Validator.required]"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-select
              :items="category"
              v-model="product_data.category"
              label="User Type"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              type="text"
              label="Price"
              outlined
              hide-details="auto"
              v-model="product_data.price"
              :rules="[Validator.required,Validator.isNumberOnly,Validator.isPositive]"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              type="text"
              label="credit"
              outlined
              hide-details="auto"
              v-model="product_data.credit"
              :rules="[Validator.required,Validator.isNumberOnly,Validator.isPositive]"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              type="text"
              label="Bonus"
              outlined
              hide-details="auto"
              v-model="product_data.bonus"
              :rules="[Validator.required,Validator.isNumberOnly,Validator.isPositive]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
                label="Description"
                height="200"
                outlined
                hide-details="auto"
                v-model="product_data.description"
            >
            </v-textarea>
          </v-col>
        </v-row>
        
        <v-row class="mt-15">
          <v-col class="text-right">
                <v-btn color="primary" @click="addVar()" :loading="submitLoading"
                >Add Product Variable</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table :loading="load_table" :headers="var_header" :items="var_list" class="table-rounded" disable-sort >
              <template v-slot:[`item.attribute`]="{ item }">
                <div class="data-table-input-div">
                  <v-text-field
                    type="text"
                    outlined
                    v-model="item.attribute"
                    :rules="[Validator.required]"
                  >
                  </v-text-field>
                </div>
              </template>
              <template v-slot:[`item.inventory`]="{ item }">
                <div class="data-table-input-div">
                  <v-text-field
                    type="text"
                    outlined
                    v-model="item.inventory"
                    :rules="[Validator.required,Validator.isNumberOnly,Validator.isPositive]"
                  >
                  </v-text-field>
                </div>
              </template>
              <template v-slot:[`item.delete`]="{ index }">
                <div class="data-table-input-div">
                  <v-icon
                    large
                    @click="deleteProductVariable(index)"
                    
                  >
                    {{ icons.mdiDelete }}
                  </v-icon>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        

        <v-row>
          <v-col>
            <v-btn color="success" :loading="submitLoading" @click="saveProductData()">儲存</v-btn>
            <v-btn v-if="!isAddProduct" color="error" class="ml-3" @click="removeProductData">刪除</v-btn>
          </v-col>

        </v-row>
      </v-card>
    </v-form>

    
    
    <LoadingDialog :load="showLoadingPage"></LoadingDialog>
    <AlertBox :type="alertBox.type" :text="alertBox.text" :show="alertBox.show"></AlertBox>
  </div>
</template>

<script>
import { Validator , MEDIA_PATH } from '@/global'
import LoadingDialog from '@/components/LoadingDialog'
import AlertBox from '@/components/AlertBox'
import DataTableWithPagination from '@/components/DataTableWithPagination.vue'
import { globalFunc } from '@/global'
import { mapActions } from 'vuex'
import { getData, postData } from '@/api'
import { mdiDelete } from '@mdi/js';



export default {
  name: 'product-management-detail',
  components: {
    LoadingDialog,
    AlertBox,
    DataTableWithPagination,
  },
  data: () => ({
    user_center_id: -1,
    verifiedError: false,
    isAddProduct: true,
    globalFunc: globalFunc,
    showLoadingPage: false,
    submitLoading: false,
    load_table: false,
    product_variable_num: 1,
    product_image: require('@/assets/images/images/no_image.png'),
    icons: {
      mdiDelete
    },
    tab: null,
    alertBox: {
      show: false,
      text: '',
      type: '',
    },
    var_header: [
      { text: 'Attribute', value: 'attribute' },
      { text: 'Stock', value: 'inventory' },
      { text: 'Action', value: 'delete' },
    ],
    var_list: [],
    backup_var_list: [],
    photos: [
        { id: 1, src: 'no_image.png' },
    ],
    record_data: [],
    load_record_table: true,
    register_detail_data: [],
    Validator: Validator,
    product_data: {},
    backup_product_data: {},
    category: ["Clothes","Drinks","ENTERTAINMENT"],

    error: {
      password: false,
    },
  }),
  methods: {
    ...mapActions(['reset']),
    /**
     * Adding a product variable row to table
    */
    addVar(){
      this.var_list.push(
        {
          id: this.product_variable_num, attribute: '', sku: '', inventory: '',
        },
      )
      this.product_variable_num++
    },
    /**
     * Deleting a row of product variable
     * @param {number} id - index of product variable row
    */
    deleteProductVariable(id) {
      this.var_list.splice(id, 1);
    },
    /**
     * Trigging the input file field
     * @param {string} id - Selector of the input file field
    */
    triggerUploadImage(id) {
      document.getElementById(id).click()
    },
    /**
     * Initializing and loading the data
    */    
    async initializeData() {
      try {
        this.load_table= false
        let params = new URLSearchParams()
        params.set(
          'get_product_by_id',
          JSON.stringify({
            id: this.$route.params.id,
          }),
        )
        let result = await getData(params)
        if (result) {
          console.log('--- Get product data ---')
          console.log(result)

          let data = result

          let temp_data = {
            name: data.name,
            price: data.price,
            credit: data.credit,
            bonus: data.bonus,
            permission_type: data.permission_type,
            description: data.description,
            category: data.category,
            active: data.active,
          }

          if(data.image) {
            this.product_image = data.image
          } else {
            this.product_image = require('@/assets/images/images/no_image.png')
          }

          console.log(data.attr);
          Object.assign(this.product_data, temp_data)
          Object.assign(this.backup_product_data, temp_data)

          for(let i=0;i<data.attr.length;i++) {
            this.var_list.push({
              attribute: data.attr[i],
              inventory: data.inventory[data.attr[i]],
            })
            this.backup_var_list.push({
              attribute: data.attr[i].attribute,
              inventory: data.attr[i].inventory,
            })
          }
          this.load_table = false;
          
        }
      } catch (error) {
        console.log('--- Get product data fail ---')
        console.log(error)
      }
    },
    /**
     * upload product image
     * @param {file} - input from file input
    */
    async getEventImageFile(file) {
      if (globalFunc.DataValid(file)) {
        const vm = this
        if (file) {
          try {
            let bas4Path = await globalFunc.firstFileToBase64(file)

            let type = file.type.substring(file.type.lastIndexOf('/') + 1)
            let params = new URLSearchParams()
            let send_data = {
              upload_data: bas4Path,
              upload_file_type: type,
            }
            
            params.set('upload_file', JSON.stringify(send_data))

            const config = {
              onUploadProgress: progressEvent => {
                console.log(progressEvent.loaded)
                vm.progress = progressEvent.loaded * 0.75
              },
            }

            let upload_image = await postData(params, config)
            this.product_image = MEDIA_PATH + upload_image;

            vm.uploading = false
            vm.progress = 100
          } catch (error) {
            console.log(error)
            vm.uploading = false
          }
        }
      }
      
    },
    /**
     * remove product dialog
    */
        removeProductData() {
      this.dialog.text = '刪除活動'
      this.dialog.type = 'delete_event'
      this.dialog.open = true
    },
    /**
     * delete a product
     * @param {string} action - given by the dialog component
    */
    async deleteProduct() {
      try {
        let params = new URLSearchParams()
        params.set(
          'update_product',
          JSON.stringify({
            id: parseInt(this.$route.params.id),
            active: false,
          }),
        )
        let result = await getData(params)
        this.alertBox.text = '刪除成功'
        this.alertBox.type = 'success'
        this.alertBox.show = true
        setTimeout(() => {
          this.$router.replace({ name: 'product-management' })
        }, 1500)
      } catch (error) {
        console.log(error)
        this.alertBox.text = '刪除失敗'
        this.alertBox.type = 'error'
        this.alertBox.show = true
      } finally {
        setTimeout(() => {
          this.submitLoading = false
          this.alertBox.show = false
          this.submitLoading = false
        }, 1500)
      }

      // if (action) {
      //   if (this.dialog.type == 'image') {
      //     this.event_cover_image_list.splice(this.dialog.index, 1)
      //   } else if (this.dialog.type == 'preview') {
      //     this.preview_image_data.image = ''
      //     this.preview_image_data.preview_image = ''
      //     this.preview_image_data.hidePreview = true
      //     this.preview_image_data.image_file = {}
      //   } else {
      //     this.submitLoading = true
      //     try {
      //       let params = new URLSearchParams()
      //       params.set(
      //         'update_event',
      //         JSON.stringify({
      //           id: paresInt(this.$route.params.id),
      //           active: false,
      //         }),
      //       )
      //       let result = await getData(params)
      //       this.alertBox.text = '刪除成功'
      //       this.alertBox.type = 'success'
      //       this.alertBox.show = true
      //       setTimeout(() => {
      //         this.$router.replace({ name: 'event' })
      //       }, 1500)
      //     } catch (error) {
      //       console.log(error)
      //       this.alertBox.text = '刪除失敗'
      //       this.alertBox.type = 'error'
      //       this.alertBox.show = true
      //     } finally {
      //       setTimeout(() => {
      //         this.submitLoading = false
      //         this.alertBox.show = false
      //         this.submitLoading = false
      //       }, 1500)
      //     }
      //   }
      // }
    },
    /**
     * Saving the product data to backend
    */
    async saveProductData() {
       if (!this.$refs.product_form.validate()) {
        return
      }

      try {
        let key = ''
        let send_data = {}
        let attr = [];

        if (!this.isAddProduct) {
          send_data.id = parseInt(this.$route.params.id)

          let updated = false
          if (this.product_data.name != this.backup_product_data.name) {
            updated = true
            send_data.name = this.product_data.name
          }

          if (this.product_data.price != this.backup_product_data.price) {
            updated = true
            send_data.price = parseInt(this.product_data.price)
          }

          if (this.product_data.credit != this.backup_product_data.credit) {
            updated = true
            send_data.credit = parseInt(this.product_data.credit)
          }

          if (this.product_data.bonus != this.backup_product_data.bonus) {
            updated = true
            send_data.bonus = parseInt(this.product_data.bonus)
          }

          if (this.product_data.description != this.backup_product_data.description) {
            updated = true
            send_data.description = this.product_data.description
          }

          if (this.product_data.category != this.backup_product_data.category) {
            updated = true
            send_data.category = this.product_data.category
          }

          if (this.product_data.active != this.backup_product_data.active) {
            updated = true
            send_data.active = this.product_data.active
          }

          if(this.var_list.length != this.backup_var_list.length) {
            updated = true
          }

          if(this.var_list.image != this.product_image) {
            updated = true
            send_data.image = this.product_image
          }

          for(let i=0;i<this.var_list.length && i<this.backup_var_list.length;i++) {
            if(this.var_list[i].attribute!=this.backup_var_list[i].attribute) {
              updated = true;
            }
            if(this.var_list[i].inventory!=this.backup_var_list[i].inventory) {
              updated = true;
            }
          }

          send_data.attr = []
          send_data.inventory = {};
          for(let i=0;i<this.var_list.length;i++) {
            send_data.attr.push(this.var_list[i].attribute);
          }

          if(updated) {
            send_data.attr = []
            for(let i = 0; i < this.var_list.length ; i++ ){
              send_data.attr.push(this.var_list[i].attribute)
              send_data.inventory[this.var_list[i].attribute] = parseInt(this.var_list[i].inventory)
            }
          }


          console.log(this.var_list)
          console.log(send_data)
          
          if (!updated) {
            this.alertBox.text = '沒有資料需要更新'
            this.alertBox.type = 'error'
            this.alertBox.show = true
            return
          }

          key = 'update_product'
        } else {
          send_data.name = this.product_data.name
          send_data.description = this.product_data.description
          send_data.price = this.product_data.price
          send_data.credit = this.product_data.credit
          send_data.bonus = this.product_data.bonus
          send_data.active = this.product_data.active
          send_data.category = this.product_data.category

          for(let i = 0; i < this.var_list.length ; i++ ){
              send_data.attr.push(this.var_list[i].attribute)
              send_data.inventory[this.var_list[i].attribute] = parseInt(this.var_list[i].inventory)
            }

          for(let i=0;i<this.var_list.length;i++) {
            attr.push({
              attribute: this.var_list[i].attribute,
              inventory: this.var_list[i].inventory,
            })
          }

          send_data.attr = attr
          key = 'new_product'

          console.log(send_data)
          
        }

        let params = new URLSearchParams()
        send_data = JSON.parse(JSON.stringify(send_data))
        params.set(key, JSON.stringify(send_data))
        let result = await getData(params)
        console.log("getData")
        console.log(result)

        if (!Array.isArray(result.data)) {
          this.alertBox.text = '儲存成功'
          this.alertBox.type = 'success'
          this.alertBox.show = true
          setTimeout(() => {
            this.$router.replace({ name: 'product-management' })
          }, 1500)
        } else {
          this.alertBox.text = '沒有資料需要更新'
          this.alertBox.type = 'error'
          this.alertBox.show = true
        }
      } catch (error) {
        console.log(error)
        this.alertBox.text = '儲存失敗'
        this.alertBox.type = 'error'
        this.alertBox.show = true
      } finally {
        setTimeout(() => {
          this.submitLoading = false
          this.alertBox.show = false
        }, 1500)
      }
    },
    /**
     * kicking out the user
    */
    kickout() {
      console.log('--- Validation Fail ---')
      this.alertBox.text = '登入憑證過期，3秒後重新導向'
      this.alertBox.type = 'error'
      this.alertBox.show = true

      setTimeout(() => {
        this.alertBox.show = false
        let send_data = []
        send_data['method'] = 'resetData'
        this.reset(send_data)
        this.$router.push({ name: 'Login' })
      }, 3000)
    },
  },
  /**
   * Initializing at the beginning
  */
  created() {
    setTimeout(async () => {
      console.log('--- Validation ---')
      if (globalFunc.DataValid(this.$store.state.api.login_data)) {
        try {
          this.isAddProduct = true
          this.showLoadingPage = true
          setTimeout(() => {
            this.showLoadingPage = false
          }, 1000)

          if (globalFunc.DataValid(this.$route.params.id)) {
            this.isAddProduct = false
            this.initializeData()
          }
        } catch (error) {
          console.log('--- Check User Permission Fail ---')
          console.log(error)
          this.verifiedError = true
          this.showLoadingPage = false
          this.alertBox.text = '資料取得失敗'
          this.alertBox.type = 'error'
          this.alertBox.show = true

          setTimeout(() => {
            this.alertBox.show = false
            this.$router.replace({ name: 'product-management' })
          }, 3000)
        }
      } else {
        this.kickout()
      }
    }, 200)
  },
  watch: {
    /*     'product_data.user_type': function (newVal) {
      if (this.isAddProduct) {
        if (this.center_readOnly) {
          this.center_readOnly = false
          this.center_rules = [Validator.required]
        }
      }
    }, */
    // 'product_data.permission_type': function (newVal, oldVal) {
    //   if (this.isAddProduct) {
    //     if (this.center_readOnly) {
    //       this.center_readOnly = false
    //       this.center_rules = [Validator.required]
    //     }
    //     if (newVal == 'super_admin' && (oldVal == '' || oldVal == 'normal')) {
    //       this.center_list.splice(0, 0, { 'item-text': '沒有所屬中心', 'item-value': -1 })
    //     } else if (newVal == 'normal' && oldVal == 'super_admin') {
    //       this.center_list.splice(0, 1)
    //     }
    //   } else {
    //     if (this.user_center_id == -1) {
    //       this.center_readOnly = false
    //       this.center_rules = [Validator.required]
    //       if (newVal == 'super_admin' && (oldVal == '' || oldVal == 'normal')) {
    //         this.center_list.splice(0, 0, { 'item-text': '沒有所屬中心', 'item-value': -1 })
    //       } else if (newVal == 'normal' && oldVal == 'super_admin') {
    //         this.center_list.splice(0, 1)
    //       }
    //     } else {
    //       this.center_readOnly = true
    //       this.center_rules = []
    //     }
    //   }
    // },
  },
}
</script>
